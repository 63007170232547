import { mdiCheckOutline, mdiCogTransferOutline, mdiDeleteOutline, mdiEmailEditOutline, mdiEmailRemoveOutline, mdiEmailSyncOutline, mdiEyeOffOutline } from "@mdi/js";

const data = {
  kraniCards: [
    { id: 1, dummyNum: 2, color: "primary", slug: "unread_memo", to: "memo/inbox", title: "card.unread-memo", desc: "text.you-need-to-read-this", icon: mdiEyeOffOutline },
    { id: 2, dummyNum: 12, color: "success", slug: "finished_job", to: "/task/disposition", title: "card.finished-job", desc: "text.your-job-has-finished", icon: mdiCheckOutline },
    { id: 3, dummyNum: 3, color: "warning", slug: "pending_disposition", to: "/task/disposition", title: "card.pending-disposition", desc: "text.please-make-these-done", icon: mdiCogTransferOutline },
    { id: 4, dummyNum: 5, color: "error", slug: "terminated_draft", to: "memo/terminated", title: "card.terminated-draft", desc: "text.your-draft-that-has-terminated", icon: mdiDeleteOutline },
  ],
  karpimCards: [
    { id: 1, dummyNum: 2, color: "primary", slug: "unread_memo", to: "/memo/inbox", title: "card.unread-memo", desc: "text.you-need-to-read-this", icon: mdiEyeOffOutline },
    { id: 2, dummyNum: 30, color: "success", slug: "finished_job", to: "/task/disposition", title: "card.finished-job", desc: "text.your-job-has-finished", icon: mdiCheckOutline },
    { id: 3, dummyNum: 3, color: "warning", slug: "pending_revision", to: "/memo/revision", title: "card.pending-revision", desc: "text.these-draft-need-to-revise-or-terminate", icon: mdiEmailEditOutline },
    { id: 4, dummyNum: 2, color: "warning", slug: "pending_disposition", to: "/task/disposition", title: "card.pending-disposition", desc: "text.please-make-these-done", icon: mdiCogTransferOutline },
    { id: 5, dummyNum: 10, color: "error", slug: "terminated_draft", to: "/memo/terminated", title: "card.terminated-draft", desc: "text.your-draft-that-has-terminated", icon: mdiDeleteOutline },
  ],
  pjpCards: [
    { id: 1, dummyNum: 4, color: "primary", slug: "unread_memo", to: "/memo/inbox", title: "card.unread-memo", desc: "text.you-need-to-read-this", icon: mdiEyeOffOutline },
    { id: 2, dummyNum: 20, color: "success", slug: "finished_job", to: "/task/disposition", title: "card.finished-job", desc: "text.your-job-has-finished", icon: mdiCheckOutline },
    { id: 3, dummyNum: 2, color: "warning", slug: "pending_approval", to: "/memo/draft-approval", title: "card.draft-pending-approval", desc: "text.these-draft-need-to-approve-or-terminate", icon: mdiEmailSyncOutline },
    { id: 4, dummyNum: 5, color: "warning", slug: "pending_disposition", to: "/task/disposition", title: "card.pending-disposition", desc: "text.please-make-these-done", icon: mdiCogTransferOutline },
    { id: 5, dummyNum: 13, color: "error", slug: "terminated_draft", to: "/memo/terminated", title: "card.terminated-draft", desc: "text.your-draft-that-has-terminated", icon: mdiDeleteOutline },
    { id: 6, dummyNum: 2, color: "error", slug: "revoked_memo", to: "/memo/revoked", title: "card.revoked-memo", desc: "text.the-memo-that-revoked-after-send", icon: mdiEmailRemoveOutline },
  ],
  dirCards: [
    { id: 1, dummyNum: 4, color: "primary", slug: "unread_memo", to: "/memo/inbox", title: "card.unread-memo", desc: "text.you-need-to-read-this", icon: mdiEyeOffOutline },
    { id: 1, dummyNum: 4, color: "primary", slug: "unread_incoming", to: "/memo/incoming", title: "card.unread-incoming", desc: "text.you-need-to-read-this", icon: mdiEyeOffOutline },
    { id: 1, dummyNum: 4, color: "success", slug: "unread_permit", to: "/memo/permit", title: "card.unread-permit", desc: "text.you-need-to-read-this-permit", icon: mdiEyeOffOutline },
    { id: 4, dummyNum: 2, color: "warning", slug: "pending_permit_disposition", to: "/memo/permit", title: "card.pending-permit-disposition", desc: "text.you-need-to-process-this-permit", icon: mdiCogTransferOutline },
    { id: 3, dummyNum: 6, color: "warning", slug: "pending_approval", to: "/memo/draft-approval", title: "card.draft-pending-approval", desc: "text.these-draft-need-to-approve-or-terminate", icon: mdiEmailSyncOutline },
    { id: 4, dummyNum: 10, color: "error", slug: "terminated_draft", to: "/memo/terminated", title: "card.terminated-draft", desc: "text.your-draft-that-has-terminated", icon: mdiDeleteOutline },
    { id: 5, dummyNum: 2, color: "error", slug: "revoked_memo", to: "/memo/revoked", title: "card.revoked-memo", desc: "text.the-memo-that-revoked-after-send", icon: mdiEmailRemoveOutline },
  ],
};

export default data;