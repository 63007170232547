<template>
  <v-flex fill-height class="px-2">
    <v-row>
      <v-col v-for="(item, i) in myCards" :key="i" cols="12" md="4" sm="12">
        <v-card
          :color="item.color"
          class="py-2 height__exact-150"
          v-on:click="$router.push(item.to)"
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="headline white--text">
                <p class="dashboard__card-count">
                  {{ countNumber(item.slug) }}
                </p>
                {{ $t(item.title) }}
              </v-card-title>

              <v-card-subtitle
                class="white--text font-size-default"
                v-text="$t(item.desc)"
              ></v-card-subtitle>
            </div>
            <div class="dashboard__card-icon">
              <v-icon>{{ item.icon }}</v-icon>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-flex>
</template>
<script>
import dashboardCard from "@/constants/dashboardcard";
import { mapActions, mapState } from "vuex";
export default {
  data: () => ({
    cardList: dashboardCard,
  }),
  computed: {
    ...mapState("user", ["currentUser"]),
    ...mapState("resources", ["myDashboardCount"]),
    dashboardCount() {
      if (!this.myDashboardCount || !this.myDashboardCount.counting)
        return null;
      return this.myDashboardCount.counting;
    },
    myCards() {
      if (!this.cardList || !this.currentUser) return [];
      let result = [];
      switch (this.currentUser.level_aplikasi_elemen) {
        case "KRANI":
        case "SEKRETARIS_KRANI":
          result = this.cardList.kraniCards;
          break;
        case "KARPIM":
        case "KARPIM_KANPUS":
        case "KADIS":
        case "KASUBAG":
          result = this.cardList.karpimCards;
          break;
        case "PJP":
        case "PJP_KANPUS":
          result = this.cardList.pjpCards;
          break;
        case "DIREKTUR":
          result = this.cardList.dirCards;
          break;
        default:
          break;
      }
      return result;
    },
  },
  created() {
    this.getDashboardCount();
  },
  methods: {
    ...mapActions("resources", ["getDashboardCount"]),
    countNumber(slug) {
      let result = 0;
      if (!this.dashboardCount || !slug) return result;
      // done: 0
      // pendingApproval: 0
      // pendingDisposition: 0
      // pendingRevision: 0
      // revoked: 0
      // terminatedDrafts: 3
      // unread: 0
      switch (slug) {
        case "pending_revision":
        case "pending_approval":
          result = this.dashboardCount.pendingRevision;
          break;
        case "unread_memo":
          result = this.dashboardCount.unread;
          break;
        case "unread_incoming":
          result = this.dashboardCount.unreadIncoming;
          break;
        case "unread_permit":
          result = this.dashboardCount.unreadIzinPrinsip;
          break;
        case "pending_permit_disposition":
          result = this.dashboardCount.pendingIzinPrinsip;
          break;
        case "finished_job":
          result = this.dashboardCount.done;
          break;
        case "pending_disposition":
          result = this.dashboardCount.pendingDisposition;
          break;
        case "terminated_draft":
          result = this.dashboardCount.terminatedDrafts;
          break;
        case "revoked_memo":
          result = this.dashboardCount.revoked;
          break;
        default:
          break;
      }
      return result;
    },
  },
};
</script>
<style>
.min__height-200 {
  height: 230px;
}
</style>
